import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import BaseLayout from '../layouts/BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Content } from '../components/staticContents';

import { api } from '../services';

const PlanInactivo = () => {
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const isLogged = useSelector(({ login }) => login.isLogged);
  const currentRole = useSelector(({ login }) => login.user);

  const { fetchLabelsInactivePlan } = api();

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    if (!isLogged) {
      navigate('/login', { state: { previousPath: '/menu' } });
    }
    const fetchData = async ignore => {
      if (currentRole.roleName === 'Premium Admin') {
        navigate('/planes', { state: { previousPath: '/menu' } });
      } else {
        try {
          if (!ignore) {
            const response = await fetchLabelsInactivePlan(currentLang);
            setLabels(response.labels);
          }
        } catch (e) {
          setError(e.message);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <BaseLayout title={'(defi)² - Plan Inactivo'}>
      <section className="step-container background-step-default">
        <Header to="/menu" previous={'/plan-inactivo'} logo="amarillo" />
        <div className="container-fluid">
          {loading ? (
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <Loading iconColor="amarillo" />
            </div>
          ) : (
            <Content
              title={labels.titleInactivePlan || 'NULL'}
              content={labels.contentInactivePlan || 'NULL'}
            />
          )}
        </div>
      </section>
    </BaseLayout>
  );
};

export default PlanInactivo;
